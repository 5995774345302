import React, { Fragment, useCallback, useState } from 'react';
import 'bulma/css/bulma.min.css';
import './App.css';
import { Container, Navbar } from 'react-bulma-components';
import TermsConditions from './pages/TermsConditions';
import PrivacyPolicy from './pages/PrivacyPolicy';
import { Routes, Route, Link } from 'react-router-dom';

function App() {
  const [showBurgerMenu, setShowBurgerMenu] = useState(false);
  const toggleBurgerMenu = useCallback(() => {
    setShowBurgerMenu(!showBurgerMenu);
  }, [setShowBurgerMenu, showBurgerMenu]);

  return (
    <Fragment>
      <Navbar pl={6} active={showBurgerMenu}>
        <Navbar.Brand>
          <Navbar.Item>
            <img alt='Agriknow logo' src='/images/logo.png' />
          </Navbar.Item>
          <Navbar.Burger onClick={toggleBurgerMenu} />
        </Navbar.Brand>
        <Navbar.Menu>
          <Navbar.Container>
            <Navbar.Item renderAs={Link} to='/terms_and_conditions'>{`Terms & Conditions`}</Navbar.Item>
            <Navbar.Item renderAs={Link} to='/privacy_policy'>{`Privacy Policy`}</Navbar.Item>
          </Navbar.Container>
        </Navbar.Menu>
      </Navbar>

      <Container>
        <Routes>
          <Route path='/terms_and_conditions' element={<TermsConditions />} />
          <Route path='/privacy_policy' element={<PrivacyPolicy />} />
          <Route path='/' element={<TermsConditions />} />
        </Routes>
      </Container>
    </Fragment>
  );
}

export default App;
