import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
};

export type Acknowledgement = {
  __typename?: 'Acknowledgement';
  companyName: Scalars['String'];
  id: Scalars['ID'];
  logo?: Maybe<Media>;
};

export type Contact = {
  __typename?: 'Contact';
  bio?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  image?: Maybe<Media>;
  name: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
};

export type Content = {
  __typename?: 'Content';
  assetUrl?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  hashtags: Array<Hashtag>;
  id: Scalars['ID'];
  lastUpdatedAt: Scalars['DateTime'];
  likedByMe: Scalars['Boolean'];
  likesCount: Scalars['Int'];
  publishedAt: Scalars['DateTime'];
  sharedByMe: Scalars['Boolean'];
  sharesCount: Scalars['Int'];
  thumbnailUrl?: Maybe<Scalars['String']>;
  type: ContentType;
  url: Scalars['String'];
};

export enum ContentType {
  Audio = 'AUDIO',
  Image = 'IMAGE',
  Object_3D = 'OBJECT_3D',
  Video = 'VIDEO',
  Video360 = 'VIDEO360',
  VirtualExperience = 'VIRTUAL_EXPERIENCE'
}

export type Coordinate = {
  __typename?: 'Coordinate';
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type CoordinateInput = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type DeleteUserInput = {
  confirmDeleteAccount?: InputMaybe<Scalars['Boolean']>;
  deleteAccount?: InputMaybe<Scalars['Boolean']>;
};

export type Device = {
  __typename?: 'Device';
  id: Scalars['ID'];
  name: Scalars['String'];
  os: Scalars['String'];
  token: Scalars['String'];
};

export type DeviceInput = {
  name: Scalars['String'];
  os: Scalars['String'];
  token: Scalars['String'];
};

export type Enquiry = {
  __typename?: 'Enquiry';
  content: Scalars['String'];
  email: Scalars['String'];
  id: Scalars['ID'];
};

export type EnquiryInput = {
  content?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
};

export type Faq = {
  __typename?: 'Faq';
  answer: Scalars['String'];
  id: Scalars['ID'];
  question: Scalars['String'];
};

export type Hashtag = {
  __typename?: 'Hashtag';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Industry = {
  __typename?: 'Industry';
  icon?: Maybe<Media>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type IndustryConnection = {
  __typename?: 'IndustryConnection';
  edges?: Maybe<Array<Maybe<IndustryEdge>>>;
  pageInfo: PageInfo;
};

export type IndustryEdge = {
  __typename?: 'IndustryEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<Industry>;
};

export type Like = {
  __typename?: 'Like';
  content?: Maybe<Content>;
  id: Scalars['ID'];
  post?: Maybe<Post>;
  project?: Maybe<Project>;
};

export type LikeConnection = {
  __typename?: 'LikeConnection';
  edges?: Maybe<Array<Maybe<LikeEdge>>>;
  pageInfo: PageInfo;
};

export type LikeEdge = {
  __typename?: 'LikeEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<Like>;
};

export type Media = {
  __typename?: 'Media';
  id: Scalars['ID'];
  thumbnailUrl: Scalars['String'];
  url: Scalars['String'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

export type Post = {
  __typename?: 'Post';
  content: Array<Content>;
  description: Scalars['String'];
  hashtags: Array<Hashtag>;
  id: Scalars['ID'];
  lastUpdatedAt: Scalars['DateTime'];
  likedByMe: Scalars['Boolean'];
  likesCount: Scalars['Int'];
  project?: Maybe<Project>;
  publishedAt: Scalars['DateTime'];
  sharedByMe: Scalars['Boolean'];
  sharesCount: Scalars['Int'];
  site?: Maybe<Site>;
  title: Scalars['String'];
  trial?: Maybe<Trial>;
  type: PostType;
};

export type PostConnection = {
  __typename?: 'PostConnection';
  edges?: Maybe<Array<Maybe<PostEdge>>>;
  pageInfo: PageInfo;
};

export type PostEdge = {
  __typename?: 'PostEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<Post>;
};

export enum PostOrder {
  MostPopular = 'MOST_POPULAR',
  MostRecent = 'MOST_RECENT'
}

export type PostType = {
  __typename?: 'PostType';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type PostTypeConnection = {
  __typename?: 'PostTypeConnection';
  edges?: Maybe<Array<Maybe<PostTypeEdge>>>;
  pageInfo: PageInfo;
};

export type PostTypeEdge = {
  __typename?: 'PostTypeEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<PostType>;
};

export type Project = {
  __typename?: 'Project';
  acknowledgements: Array<Acknowledgement>;
  bookmarkedByMe: Scalars['Boolean'];
  contacts: Array<Contact>;
  description: Scalars['String'];
  faqs: Array<Faq>;
  hashtags: Array<Hashtag>;
  id: Scalars['ID'];
  image: Media;
  lastUpdatedAt: Scalars['DateTime'];
  likedByMe: Scalars['Boolean'];
  likesCount: Scalars['Int'];
  name: Scalars['String'];
  private: Scalars['Boolean'];
  publishedAt: Scalars['DateTime'];
  sharedByMe: Scalars['Boolean'];
  sharesCount: Scalars['Int'];
  trials: Array<Trial>;
  unlockedByMe: Scalars['Boolean'];
};

export type ProjectConnection = {
  __typename?: 'ProjectConnection';
  edges?: Maybe<Array<Maybe<ProjectEdge>>>;
  pageInfo: PageInfo;
};

export type ProjectEdge = {
  __typename?: 'ProjectEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<Project>;
};

export enum ProjectOrder {
  MostPopular = 'MOST_POPULAR',
  MostRecent = 'MOST_RECENT'
}

export type RootMutationType = {
  __typename?: 'RootMutationType';
  /** Adds a user push notification device if it doesnt already exist */
  addDevice: Device;
  /** Records that the piece of content was shared by the current user. */
  contentShare: Content;
  /** Likes or un-likes a piece of content. */
  contentToggleLike: Content;
  /** Updates current user's profile information */
  deleteUserProfile: User;
  /** Records that the post was shared by the current user. */
  postShare: Post;
  /** Likes or un-likes a post. */
  postToggleLike: Post;
  /** Bookmarks or un-bookmarks a project. Expected errors: not_found */
  projectBookmarkToggle: Project;
  /** Records that the project was shared by the current user. */
  projectShare: Project;
  /** Likes or un-likes a project. */
  projectToggleLike: Project;
  /** Unlocks a project for the current user with a coupon code. Expected errors: not_found */
  projectUnlock: Project;
  /** Submit enquiry */
  submitEnquiry: Enquiry;
  /** Logs in a user based on their phone number. If they don't have an existing account then a new blank account is created. Expected errors: incorrect_verification_code, invalid_phone_number, too_many_check_attempts */
  userLoginOrSignup: Session;
  /** Updates current user's profile information */
  userProfileUpdate: User;
  /** Sends a verification code to the user's phone number. This can be used for signing up, logging in or changing phone numbers. Expected errors: too_many_send_attempts, too_many_send_attempts */
  userSendPhoneNumberVerificationCode: Scalars['String'];
};


export type RootMutationTypeAddDeviceArgs = {
  deviceInput: DeviceInput;
};


export type RootMutationTypeContentShareArgs = {
  id: Scalars['ID'];
};


export type RootMutationTypeContentToggleLikeArgs = {
  id: Scalars['ID'];
};


export type RootMutationTypeDeleteUserProfileArgs = {
  confirmationInput: DeleteUserInput;
};


export type RootMutationTypePostShareArgs = {
  id: Scalars['ID'];
};


export type RootMutationTypePostToggleLikeArgs = {
  id: Scalars['ID'];
};


export type RootMutationTypeProjectBookmarkToggleArgs = {
  id: Scalars['ID'];
};


export type RootMutationTypeProjectShareArgs = {
  id: Scalars['ID'];
};


export type RootMutationTypeProjectToggleLikeArgs = {
  id: Scalars['ID'];
};


export type RootMutationTypeProjectUnlockArgs = {
  couponCode: Scalars['String'];
};


export type RootMutationTypeSubmitEnquiryArgs = {
  enquiry: EnquiryInput;
};


export type RootMutationTypeUserLoginOrSignupArgs = {
  phoneNumber: Scalars['String'];
  verificationCode: Scalars['String'];
};


export type RootMutationTypeUserProfileUpdateArgs = {
  userProfile: UserProfileInput;
};


export type RootMutationTypeUserSendPhoneNumberVerificationCodeArgs = {
  phoneNumber: Scalars['String'];
};

export type RootQueryType = {
  __typename?: 'RootQueryType';
  /** Returns all static content for the platform */
  allStaticContent?: Maybe<Array<Maybe<StaticContent>>>;
  /** Gets a single piece of content. Expected errors: not_found */
  content: Content;
  /** Lists all industries ordered alphabetically. */
  industries?: Maybe<IndustryConnection>;
  /** Returns the current user's account */
  me: User;
  /** Lists of the projects, posts and content liked by the current user. */
  myLikes?: Maybe<LikeConnection>;
  /** Gets a single post */
  post: Post;
  /** Lists all post types to be used for filtering for listing methods */
  postTypes?: Maybe<PostTypeConnection>;
  /** Lists all posts for public projects as well as from private projects unlocked by the current user. Ordered from most recent project to least by default. */
  posts?: Maybe<PostConnection>;
  /** Lists all posts for a given project. Can optionally filter for posts with a given type. Ordered from most recently published. */
  postsForProject?: Maybe<PostConnection>;
  /** Lists all posts for a given site. Can optionally filter for posts with a given type. Ordered from most recently published. */
  postsForSite?: Maybe<PostConnection>;
  /** Lists all posts for a given trial. Can optionally filter for posts with a given type. Ordered from most recently published. */
  postsForTrial?: Maybe<PostConnection>;
  /** Gets a single project. Expected errors: id_not_found */
  project: Project;
  /** Lists all public projects as well as any private projects unlocked by the current user [currently only supports public projects]. Ordered from most recent project to least by default. */
  projects?: Maybe<ProjectConnection>;
  /** Lists all projects bookmarked by a user Ordered from most recently bookmarked. */
  projectsBookmarkedByMe?: Maybe<ProjectConnection>;
  /** Searches across different entity types */
  search: Array<Maybe<SearchResult>>;
  /** Gets a single site. Expected errors: not_found */
  site: Site;
  /** Lists the sites within a geograhical boundary. */
  sitesWithinBounds: Array<Site>;
  /** Gets a single trial. Expected errors: not_found */
  trial: Trial;
};


export type RootQueryTypeContentArgs = {
  id: Scalars['ID'];
};


export type RootQueryTypeIndustriesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type RootQueryTypeMyLikesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type RootQueryTypePostArgs = {
  id: Scalars['ID'];
};


export type RootQueryTypePostTypesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type RootQueryTypePostsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderedFrom?: InputMaybe<PostOrder>;
};


export type RootQueryTypePostsForProjectArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  postTypeId?: InputMaybe<Scalars['ID']>;
  projectId: Scalars['ID'];
};


export type RootQueryTypePostsForSiteArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  postTypeId?: InputMaybe<Scalars['ID']>;
  siteId: Scalars['ID'];
};


export type RootQueryTypePostsForTrialArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  postTypeId?: InputMaybe<Scalars['ID']>;
  trialId: Scalars['ID'];
};


export type RootQueryTypeProjectArgs = {
  id: Scalars['ID'];
};


export type RootQueryTypeProjectsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderedFrom?: InputMaybe<ProjectOrder>;
};


export type RootQueryTypeProjectsBookmarkedByMeArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type RootQueryTypeSearchArgs = {
  query: Scalars['String'];
  scope: SearchScope;
};


export type RootQueryTypeSiteArgs = {
  id: Scalars['ID'];
};


export type RootQueryTypeSitesWithinBoundsArgs = {
  northWestBounds: CoordinateInput;
  southEastBounds: CoordinateInput;
};


export type RootQueryTypeTrialArgs = {
  id: Scalars['ID'];
};

/** Display search results to the given query */
export type SearchResult = Post | Project | Site | Trial;

export enum SearchScope {
  Posts = 'POSTS',
  Projects = 'PROJECTS',
  Sites = 'SITES',
  Trials = 'TRIALS'
}

export type Session = {
  __typename?: 'Session';
  token: Scalars['String'];
  user: User;
};

export type Site = {
  __typename?: 'Site';
  description?: Maybe<Scalars['String']>;
  hashtags: Array<Hashtag>;
  id: Scalars['ID'];
  image?: Maybe<Media>;
  lastUpdatedAt: Scalars['DateTime'];
  location: Coordinate;
  name: Scalars['String'];
  publishedAt: Scalars['DateTime'];
  trial: Trial;
};

export type StaticContent = {
  __typename?: 'StaticContent';
  content?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  type?: Maybe<StaticContentType>;
};

export enum StaticContentType {
  PrivacyPolicy = 'PRIVACY_POLICY',
  TermsAndConditions = 'TERMS_AND_CONDITIONS'
}

export type Trial = {
  __typename?: 'Trial';
  acknowledgements: Array<Acknowledgement>;
  contacts: Array<Contact>;
  description: Scalars['String'];
  hashtags: Array<Hashtag>;
  id: Scalars['ID'];
  image: Media;
  lastUpdatedAt: Scalars['DateTime'];
  name: Scalars['String'];
  project: Project;
  publishedAt: Scalars['DateTime'];
  sites: Array<Site>;
};

export type User = {
  __typename?: 'User';
  email?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  occupation?: Maybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
  state: UserState;
};

export type UserProfileInput = {
  email?: InputMaybe<Scalars['String']>;
  fullName?: InputMaybe<Scalars['String']>;
  occupation?: InputMaybe<Scalars['String']>;
};

export enum UserState {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type AllStaticContentQueryVariables = Exact<{ [key: string]: never; }>;


export type AllStaticContentQuery = { __typename?: 'RootQueryType', allStaticContent?: Array<{ __typename?: 'StaticContent', content?: string | null, id: string, type?: StaticContentType | null } | null> | null };

export type StaticContentBaseFragment = { __typename?: 'StaticContent', content?: string | null, id: string, type?: StaticContentType | null };

export const StaticContentBaseFragmentDoc = gql`
    fragment StaticContentBase on StaticContent {
  content
  id
  type
}
    `;
export const AllStaticContentDocument = gql`
    query allStaticContent {
  allStaticContent {
    ...StaticContentBase
  }
}
    ${StaticContentBaseFragmentDoc}`;

/**
 * __useAllStaticContentQuery__
 *
 * To run a query within a React component, call `useAllStaticContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllStaticContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllStaticContentQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllStaticContentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AllStaticContentQuery, AllStaticContentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<AllStaticContentQuery, AllStaticContentQueryVariables>(AllStaticContentDocument, options);
      }
export function useAllStaticContentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllStaticContentQuery, AllStaticContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<AllStaticContentQuery, AllStaticContentQueryVariables>(AllStaticContentDocument, options);
        }
export type AllStaticContentQueryHookResult = ReturnType<typeof useAllStaticContentQuery>;
export type AllStaticContentLazyQueryHookResult = ReturnType<typeof useAllStaticContentLazyQuery>;
export type AllStaticContentQueryResult = ApolloReactCommon.QueryResult<AllStaticContentQuery, AllStaticContentQueryVariables>;