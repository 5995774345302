import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { relayStylePagination } from '@apollo/client/utilities';
// import Config from 'react-native-config';
import logError from './utils/logError';

const GRAPHQL_URL = process.env.REACT_APP_GRAPHQL;

const httpLink = createHttpLink({
  uri: GRAPHQL_URL,
});

const authLink = setContext((_, { headers }) => {
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: '',
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(logError);
  }

  if (networkError) {
    // eslint-disable-next-line no-console
    console.log('Network error occurred', { networkError });
  }
});

const retryLink = new RetryLink();

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      merge: true,
      fields: {
        listUserAspirations: relayStylePagination(),
        listUserScorecards: relayStylePagination(),
      },
    },
  },
});

const client = new ApolloClient({
  link: errorLink.concat(retryLink.concat(authLink.concat(httpLink))),
  cache: cache,
  connectToDevTools: true,
});

export default client;
