import React from 'react';
import { Container, Content } from 'react-bulma-components';
import { useAllStaticContentQuery } from '../graphql/generated';

const PrivacyPolicy = () => {
  const { data } = useAllStaticContentQuery();

  if (data?.allStaticContent?.[1]?.content) {
    return (
      <Container m={5}>
        <Content dangerouslySetInnerHTML={{ __html: data?.allStaticContent?.[1]?.content }} />
      </Container>
    );
  } else return <div></div>;
};

export default PrivacyPolicy;
